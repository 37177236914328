import axios from "axios"
import { isNil } from "lodash"
import moment from "moment"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { API_URI, SCHOOL } from "../constants"
import { useGetToken } from "./auth"
import { getSchool } from "./school-setup"

export interface NewsArticle {
  _id: string
  school: SCHOOL
  date: string
  author?: string
  titleEng?: string
  title: string
  content: string
  contentPlain: string
  contentEng?: string
  contentPlainEng?: string
}

const queryKey = "news"

export function useGetNews() {
  const tokenQuery = useGetToken()

  async function getNews() {
    const token = tokenQuery.data?.token
    if (token) {
      const result = await axios.get(`${API_URI}/${getSchool()}/news`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return result.data
    }
  }

  return useQuery([queryKey], getNews)
}

export function useDeleteArticle() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function deleteArticle(id: string) {
    const result = await axios.delete(`${API_URI}/${getSchool()}/news/${id}`, {
      headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
    })
    return result.data
  }

  return useMutation(deleteArticle, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export function useCreateArticle() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function createArticle(article: Omit<NewsArticle, "_id">) {
    if (isNil(article) || !tokenQuery.data?.token) {
      return
    }
    const body = {
      ...article,
      date: moment(article.date).toDate().toISOString(),
    }
    const result = await axios.post(`${API_URI}/${getSchool()}/news`, body, {
      headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
    })
    return result.data
  }

  return useMutation(createArticle, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export function useUpdateArticle() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function updateFunction(article: NewsArticle) {
    if (isNil(article) || !tokenQuery.data?.token) {
      return
    }
    const body = {
      ...article,
      date: moment(article.date).toDate().toISOString(),
      _id: undefined,
    }
    const result = await axios.patch(
      `${API_URI}/${getSchool()}/news/${article._id}`,
      body,
      {
        headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
      }
    )
    return result.data
  }

  return useMutation(updateFunction, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}
