import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled, { ThemeProvider } from "styled-components"
import borgarLoginImage from "../assets/borgar-login.jpg"
import migLoginImage from "../assets/mig-login.jpg"
import pauliLoginImage from "../assets/pauli-login.jpg"
import { SCHOOL } from "../constants"
import { useAuthenticatedMutation, useIsAuthenticated } from "../data/auth"
import { warmUp } from "../data/lambda-warmer"
import { getSchool, getSchoolName } from "../data/school-setup"
import { lightTheme } from "../ui/app-layout"
import { spacing } from "../ui/spacing"

export function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [stayLoggedIn, setStayLoggedIn] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const authenticated = useIsAuthenticated()
  const authMutation = useAuthenticatedMutation()

  async function handleLogin() {
    await authMutation.mutateAsync({ username: email, password })
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleLogin()
    }
  }

  function handleStayLoggedIn() {
    setStayLoggedIn(!stayLoggedIn)
  }

  useEffect(() => {
    console.log("Current path ", location.pathname)
    if (
      authenticated.data === true &&
      (location.pathname === "/login" || location.pathname === "/")
    ) {
      navigate("/canceled")
    }
  })

  useEffect(() => {
    warmUp()
  }, [])

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <Wrapper>
          <CenterdDiv>
            <LoginForm>
              <Typography
                variant="subtitle2"
                component="div"
                gutterBottom
                sx={{ textAlign: "center" }}
                color="text.primary"
              >
                {getSchoolName()}appen
              </Typography>
              <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                sx={{ textAlign: "center" }}
                color="text.primary"
              >
                Inloggning
              </Typography>
              <TextField
                label="Email"
                type="email"
                value={email}
                onKeyDown={handleKeyPress}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Lösenord"
                type="password"
                value={password}
                onKeyDown={handleKeyPress}
                onChange={(e) => setPassword(e.target.value)}
              />
              {false && (
                <StayLoggedIn>
                  <Checkbox
                    checked={stayLoggedIn}
                    onChange={handleStayLoggedIn}
                    value={stayLoggedIn}
                  />
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.primary"
                    onClick={handleStayLoggedIn}
                    sx={{ cursor: "pointer" }}
                  >
                    Förbli inloggad
                  </Typography>
                </StayLoggedIn>
              )}
              <Button variant={"contained"} onClick={handleLogin}>
                {authMutation.isLoading ? (
                  <CircularProgress
                    sx={{
                      height: "24px !important",
                      width: "24px !important",
                      color: "white",
                    }}
                  />
                ) : (
                  "Logga in"
                )}
              </Button>
              {authMutation.isError ? (
                <Alert severity="error">{authMutation.error.message}</Alert>
              ) : (
                ""
              )}
              {authenticated.data ? (
                <Alert severity="success">Logged in</Alert>
              ) : (
                ""
              )}
              <div style={{ marginBottom: "50px" }}></div>
            </LoginForm>
            <Image />
          </CenterdDiv>
        </Wrapper>
      </ThemeProvider>
    </>
  )
}

const loginImage =
  getSchool() === SCHOOL.BORGAR
    ? borgarLoginImage
    : getSchool() === SCHOOL.MIG
    ? migLoginImage
    : getSchool() === SCHOOL.PAULI
    ? pauliLoginImage
    : ""

const StayLoggedIn = styled.div`
  display: flex;
  align-items: center;
  margin: 0 !important;
  margin-bottom: 10px !important;
`

const Image = styled.div`
  background: url(${loginImage});
  background-position: bottom;
  background-size: cover;
  flex-grow: 1;
  border-radius: 0 5px 5px 0;
`

const CenterdDiv = styled.div`
  background-color: white;
  display: flex;
  width: 80%;
  min-width: 700px;
  max-width: 1000px;
  height: 70vh;
  min-height: 500px;
  max-height: 750px;
  border-radius: 5px;
  -moz-border-radius: 5px;
`

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: relative;
  background-color: #141415;
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: ${spacing.XL} !important;
  max-width: 300px;
  min-width: 300px;
  min-height: 300px;
  > div {
    margin: 10px 0;
  }
`
