import axios from "axios"
import { isNil } from "lodash"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { API_URI, SCHOOL } from "../constants"
import { useGetToken } from "./auth"
import { getSchool } from "./school-setup"

export interface Teacher {
  _id: string
  school: SCHOOL
  name: string
  signature: string
  office?: string
  phone?: string
  email?: string
}

const queryKey = "teachers"

export function useGetTeachers() {
  const tokenQuery = useGetToken()

  async function get() {
    const token = tokenQuery.data?.token
    if (token) {
      const result = await axios.get(`${API_URI}/${getSchool()}/teachers`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return result.data
    }
  }

  return useQuery([queryKey], get)
}

export function useDeleteTeacher() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function deleteTeacher(id: string) {
    const result = await axios.delete(
      `${API_URI}/${getSchool()}/teachers/${id}`,
      {
        headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
      }
    )
    return result.data
  }

  return useMutation(deleteTeacher, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export function useCreateTeacher() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function createTeacher(teacher: Omit<Teacher, "_id">) {
    if (isNil(teacher) || !tokenQuery.data?.token) {
      return
    }
    const result = await axios.post(
      `${API_URI}/${getSchool()}/teachers`,
      teacher,
      {
        headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
      }
    )
    return result.data
  }

  return useMutation(createTeacher, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export function useUpdateTeacher() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function updateTeacher(teacher: Teacher) {
    if (isNil(teacher) || !tokenQuery.data?.token) {
      return
    }

    const result = await axios.patch(
      `${API_URI}/${getSchool()}/teachers/${teacher._id}`,
      teacher,
      {
        headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
      }
    )
    return result.data
  }

  return useMutation(updateTeacher, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}
