import { SCHOOL } from "../constants"

export function getSchool(): SCHOOL {
  const hostname = window.location.hostname
  if (hostname.includes("borgar")) {
    return SCHOOL.BORGAR
  } else if (hostname.includes("mig")) {
    return SCHOOL.MIG
  } else if (hostname.includes("pauli")) {
    return SCHOOL.PAULI
  }
  return SCHOOL.MIG
}

export function getSchoolName(): string {
  const school = getSchool()
  return school[0].toUpperCase() + school.slice(1)
}

export function setupFavicon() {
  const favicon = document.getElementById("favicon") as HTMLAnchorElement
  const title = document.getElementById("title") as HTMLTitleElement
  switch (getSchool()) {
    case SCHOOL.BORGAR:
      favicon.href = "/favicon-borgar.png"
      title.innerText = "Borgarappen"
      break
    case SCHOOL.MIG:
      favicon.href = "/favicon-mig.png"
      title.innerText = "Migappen"
      break
    case SCHOOL.PAULI:
      favicon.href = "/favicon-pauli.png"
      title.innerText = "Pauliappen"
      break
  }
}
