import axios from "axios"
import moment from "moment"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { API_URI } from "../constants"
import { getSchool } from "./school-setup"

export interface Token {
  expiresAt: string
  token: string
  userId: string
  school?: string
}

export function setToken(token: Token): void {
  localStorage.setItem("token", JSON.stringify(token))
}

export function removeToken(): void {
  localStorage.removeItem("token")
}

export function useIsAuthenticated() {
  async function getAuthenticated(): Promise<any> {
    const token = localStorage.getItem("token")
    if (token) {
      const parsed = JSON.parse(token)
      const expiresAt = moment(parsed.expiresAt).toDate()
      if (expiresAt > new Date()) {
        return true
      }
    }
    return false
  }
  return useQuery(["authenticated"], getAuthenticated)
}

export function useGetToken() {
  async function getToken(): Promise<Token | undefined> {
    const token = localStorage.getItem("token")
    if (token) {
      const parsed = JSON.parse(token)
      const expiresAt = moment(parsed.expiresAt).toDate()
      if (expiresAt > new Date()) {
        return parsed
      }
    }
    return
  }
  return useQuery(["token"], getToken)
}

export function useAuthenticatedMutation() {
  async function authenticate({
    username,
    password,
  }: {
    username: string
    password: string
  }): Promise<Token> {
    const url = API_URI
    try {
      const result = await axios.post(
        `${url}/authenticate`,
        {
          school: getSchool(),
        },
        { auth: { username, password } }
      )
      console.log(result.data)
      return result.data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const data = err.response?.data
        throw new Error(
          (data as any)?.errorDescription ?? "Fel användarnamn eller lösenord"
        )
      }
      throw new Error("Unknown error")
    }
  }

  const queryClient = useQueryClient()
  return useMutation<any, Error, any>(authenticate, {
    onSuccess: (data) => {
      setToken(data)
      queryClient.invalidateQueries("authenticated")
      queryClient.invalidateQueries("token")
    },
  })
}
