import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Typography from "@mui/material/Typography"
import moment from "moment"
import { useEffect, useState } from "react"
import { useIsAuthenticated } from "../../data/auth"
import { NewsArticle, useDeleteArticle, useGetNews } from "../../data/news"
import { DeleteDialog } from "../../ui/delete-dialog"
import { NewsEdit } from "./news-edit"

export function NewsOverview() {
  const getNews = useGetNews()
  const deleteArticle = useDeleteArticle()
  const isAuthenticated = useIsAuthenticated()

  const [edit, setEdit] = useState(false)
  const [articleToEdit, setArticleToEdit] = useState<NewsArticle | undefined>(
    undefined
  )
  const [articleToDelete, setArticleToDelete] = useState<
    NewsArticle | undefined
  >(undefined)

  async function handleDelete(article: NewsArticle) {
    await deleteArticle.mutateAsync(article._id)
    setArticleToDelete(undefined)
  }

  useEffect(() => {
    if (!getNews.data) {
      getNews.refetch()
    }
  }, [getNews, isAuthenticated.data])

  useEffect(() => {
    if (edit === false) {
      setArticleToEdit(undefined)
    }
  }, [edit])

  return (
    <>
      <Typography variant="h3" component="div" gutterBottom>
        Nyheter
      </Typography>
      <Button disabled={getNews.isLoading} onClick={() => setEdit(true)}>
        Ny post
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Titel</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Skapad av</TableCell>
              <TableCell>Innehåll</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getNews.isLoading ? (
              <CircularProgress />
            ) : (
              getNews.data
                ?.sort((a: NewsArticle, b: NewsArticle) => a.date < b.date)
                ?.map((article: NewsArticle) => (
                  <TableRow key={article._id}>
                    <TableCell>{article.title}</TableCell>
                    <TableCell sx={{ minWidth: "120px" }}>
                      {moment(article.date).format("HH:mm DD MMM")}
                    </TableCell>
                    <TableCell>{article.author}</TableCell>
                    <TableCell>
                      {article.contentPlain.length > 250
                        ? article.contentPlain.substring(
                            0,
                            250 +
                              article.contentPlain.substring(250).indexOf(" ")
                          ) + "..."
                        : article.contentPlain}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setArticleToEdit(article)
                          setEdit(true)
                        }}
                      >
                        Ändra
                      </Button>
                      <Button onClick={() => setArticleToDelete(article)}>
                        Ta bort
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {edit && (
        <NewsEdit open={edit} setOpen={setEdit} article={articleToEdit} />
      )}
      {articleToDelete && (
        <DeleteDialog
          onConfirm={() => handleDelete(articleToDelete)}
          onClose={() => setArticleToDelete(undefined)}
          title="Ta bort nyhet"
          text="Är du säker på att du vill ta bort nyheten?"
        />
      )}
    </>
  )
}
