import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useIsAuthenticated } from "../data/auth"
import { AppLayout } from "../ui/app-layout"

export function PrivateRoute({ component }: { component?: any }) {
  const authenticated = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    if (authenticated.isLoading === false && authenticated.data === false) {
      navigate("/login")
    }
  }, [authenticated, navigate])

  return <AppLayout>{component}</AppLayout>
}
