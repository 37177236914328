import { Button, CircularProgress, Dialog, TextField } from "@mui/material/"
import moment from "moment"
import { useState } from "react"
import styled from "styled-components"
import { FoodWeek, useCreateFood, useUpdateFood } from "../../data/food"
import { getSchool } from "../../data/school-setup"
import { spacing } from "../../ui/spacing"

export function FoodEdit({
  open,
  setOpen,
  foodWeek,
}: {
  open: boolean
  setOpen: any
  foodWeek?: FoodWeek
}) {
  const createFunction = useCreateFood()
  const updateFunction = useUpdateFood()

  const [state, setState] = useState<
    Omit<FoodWeek, "_id" | "date"> & { week: number }
  >({
    week: foodWeek ? moment(foodWeek.date).isoWeek() : moment().isoWeek(),
    school: getSchool(),
    monday: {
      sv: foodWeek?.monday.sv ?? "",
    },
    tuesday: {
      sv: foodWeek?.tuesday.sv ?? "",
    },
    wednesday: {
      sv: foodWeek?.wednesday.sv ?? "",
    },
    thursday: {
      sv: foodWeek?.thursday.sv ?? "",
    },
    friday: {
      sv: foodWeek?.friday.sv ?? "",
    },
  })

  async function handleSave() {
    // Do a deconstruction to exlude the week attribute and add the date
    const { week, ...modifiedState } = {
      ...state,
      date: moment().isoWeek(state.week).toDate().toISOString(),
    }
    if (foodWeek) {
      await updateFunction.mutateAsync({ _id: foodWeek._id, ...modifiedState })
    } else {
      await createFunction.mutateAsync(modifiedState)
    }
    setOpen(false)
  }

  async function handleChange(e: any) {
    if (e.target.name.includes(".")) {
      const keys = e.target.name.split(".")
      setState({ ...state, [keys[0]]: { [keys[1]]: e.target.value } })
    } else {
      setState({ ...state, [e.target.name]: e.target.value })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogWrapper>
        <h1>{foodWeek ? "Ändra" : "Ny"} Matsedel</h1>
        <TextField
          label="Vecka"
          variant="outlined"
          multiline
          type={"number"}
          minRows={1}
          name="week"
          value={state.week}
          onChange={handleChange}
        ></TextField>

        <TextField
          label="Måndag"
          variant="outlined"
          multiline
          minRows={3}
          name="monday.sv"
          value={state.monday.sv}
          onChange={handleChange}
        ></TextField>

        <TextField
          label="Tisdag"
          variant="outlined"
          multiline
          minRows={3}
          name="tuesday.sv"
          value={state.tuesday.sv}
          onChange={handleChange}
        ></TextField>
        <TextField
          label="Onsdag"
          variant="outlined"
          multiline
          minRows={3}
          name="wednesday.sv"
          value={state.wednesday.sv}
          onChange={handleChange}
        ></TextField>
        <TextField
          label="Torsdag"
          variant="outlined"
          multiline
          minRows={3}
          name="thursday.sv"
          value={state.thursday.sv}
          onChange={handleChange}
        ></TextField>
        <TextField
          label="Fredag"
          variant="outlined"
          multiline
          minRows={3}
          name="friday.sv"
          value={state.friday.sv}
          onChange={handleChange}
        ></TextField>
        <Button variant="contained" onClick={handleSave}>
          {
            /*updateFunction.isLoading || */ createFunction.isLoading ? (
              <CircularProgress />
            ) : (
              "Spara"
            )
          }
        </Button>
      </DialogWrapper>
    </Dialog>
  )
}

const DialogWrapper = styled.div`
  padding-bottom: ${spacing.XL};
  padding-right: ${spacing.XL};
  padding-left: ${spacing.XL};
  padding-top: ${spacing.M};
  overflow-x: hidden;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: ${spacing.XL};
  }
`
