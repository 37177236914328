import { AccountCircle } from "@mui/icons-material"
import LaunchIcon from "@mui/icons-material/Launch"
import {
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"
import { isNil } from "lodash"
import { useEffect, useState } from "react"
import { DarkModeToggle } from "react-dark-mode-toggle-2"

import { useQueryClient } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useGetToken } from "../data/auth"
import { spacing } from "../ui/spacing"

const drawerWidth = 240

export function Sidebar({
  darkMode,
}: {
  darkMode: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}) {
  const queryClient = useQueryClient()
  const token = useGetToken()
  const navigation = useNavigate()
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [isAdmin, setIsAdmin] = useState(false)
  const [links, setLinks] = useState<any[]>([
    {
      url: "/canceled",
      title: "Inställt",
    },
    {
      url: "/news",
      title: "Nyheter",
    },
    {
      url: "/food",
      title: "Mat",
    },
    {
      url: "/teachers",
      title: "Lärare",
    },
    {
      divider: true,
    },
    {
      url: "/timetable",
      title: "Schema",
      admin: true,
    },
    {
      url: "/r2",
      title: "R2 Files",
      admin: true,
    },
    {
      divider: true,
    },
    {
      url: "https://cloud.mongodb.com/v2/62bb0161eb8b7d53708e1af5#metrics/replicaSet/62bb084690f1b3483cc94c06/explorer",
      title: "Atlas",
      admin: true,
    },
    {
      url: "https://dash.cloudflare.com/eb30b2c7618a69512dc44754985ded67/r2/buckets/test",
      title: "Cloudflare R2",
      admin: true,
    },
    {
      url: "https://github.com/Appverkstad/skolappen-webapp",
      title: "Github - Webapp",
      admin: true,
    },
    {
      url: "https://github.com/Appverkstad/skolappen-backend",
      title: "Github - Backend",
      admin: true,
    },
    {
      url: "https://cloud.digitalocean.com/networking/domains/appverkstad.se?i=cd86a8",
      title: "Digital Ocean (DNS)",
      admin: true,
    },
    {
      url: "https://appverkstad.grafana.net/d/zNd2A5zVz/logs?orgId=1&from=now-15m&to=now&refresh=5s",
      title: "Grafana logs",
      admin: true,
    },
  ])

  function handleLogout() {
    localStorage.removeItem("token")
    queryClient.invalidateQueries("authenticated")
  }

  function isSelected(link: string): boolean {
    const path = window.location.pathname
    return path === link
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(undefined)
  }

  useEffect(() => {
    console.log("token.data updated", token.data)
    if (!isNil(token.data) && isNil(token.data.school)) {
      setIsAdmin(true)
    }
  }, [token.data])

  return (
    <>
      <CssBaseline />
      <Drawer
        open={true}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
      >
        <ProfileButtonWrapper>
          <IconButton
            sx={{
              borderRadius: "5px",
              marginBottom: "0.3rem",
              marginTop: "0.3rem",
            }}
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle sx={{ fontSize: "2rem", marginRight: "10px" }} />
            <Typography variant="h5" component="div">
              {token.data?.userId}
            </Typography>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {false && (
              <MenuItem
                onClick={() => {
                  navigation("/profile")
                }}
              >
                Konto
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>Logga ut</MenuItem>
          </Menu>
        </ProfileButtonWrapper>
        <Divider />
        <List>
          {links.map(
            (link: any) =>
              (link.admin !== true || isAdmin) &&
              (link.divider !== true ? (
                <ListItem
                  key={link.title}
                  disablePadding
                  component={link.url.includes("https") ? "a" : Link}
                  to={link.url}
                  href={link.url}
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    "&:hover": {
                      color: "primary",
                    },
                  }}
                >
                  <ListItemButton selected={isSelected(link.url)}>
                    <ListItemText
                      primary={
                        <>
                          {link.title}
                          {link.url.includes("http") && (
                            <LaunchIcon
                              sx={{
                                height: "15px",
                                fontSize: "20px",
                                lineHeight: "1.5",
                              }}
                            />
                          )}
                        </>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <Divider />
              ))
          )}
        </List>
        <DarkMode>
          <DarkModeText>Tema</DarkModeText>
          <DarkModeToggle
            onChange={(checked) => {
              darkMode[1](checked)
              window.localStorage.setItem("darkMode", checked.toString())
            }}
            isDarkMode={darkMode[0]}
            size={50}
            speed={2}
          />
        </DarkMode>
      </Drawer>
    </>
  )
}

const ProfileButtonWrapper = styled.div`
  margin-left: 10px;
`
const DarkMode = styled.div`
  display: flex;
  margin-top: auto;
  margin-left: 16px;
  margin-bottom: ${spacing.XL};
`
const DarkModeText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  margin-right: ${spacing.M};
`
