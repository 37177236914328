
export enum spacing {
  XS2 = "0.25rem",
  XS = "0.5rem",
  S = "0.75rem",
  M = "1rem",
  L = "1.5rem",
  XL = "2rem",
  XL2 = "2.5rem",
  XL3 = "3rem",
  XL4 = "4rem",
  XL5 = "6rem",
  XL6 = "8rem",
  XL7 = "16rem",
}

export const mediumWidth = "800px"
const normalWidth = "1024px"
const largeWidth = "1400px"

export const breakpoint = {
  mediumUp: `min-width: ${mediumWidth}`,
  mediumDown: `max-width: ${mediumWidth}`,

  normalDown: `max-width: ${normalWidth}`,
  normalUp: `min-width: ${normalWidth}`,

  largeDown: `max-width: ${largeWidth}`,
  largeUp: `min-width: ${largeWidth}`,
} as const

