import axios from "axios"
import { isNil } from "lodash"
import moment from "moment"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { API_URI, SCHOOL } from "../constants"
import { useGetToken } from "./auth"
import { getSchool } from "./school-setup"

export interface FoodWeek {
  _id: string
  school: SCHOOL
  date: string // Denotes the first day of the week
  monday: {
    sv: string
    en?: string
  }
  tuesday: {
    sv: string
    en?: string
  }
  wednesday: {
    sv: string
    en?: string
  }
  thursday: {
    sv: string
    en?: string
  }
  friday: {
    sv: string
    en?: string
  }
}

const queryKey = "food"

export function useGetFood() {
  const tokenQuery = useGetToken()

  async function get() {
    const token = tokenQuery.data?.token
    if (token) {
      const result = await axios.get(`${API_URI}/${getSchool()}/food`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return result.data
    }
  }

  return useQuery([queryKey], get)
}

export function useDeleteFood() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function deleteFood(id: string) {
    const result = await axios.delete(`${API_URI}/${getSchool()}/food/${id}`, {
      headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
    })
    return result.data
  }

  return useMutation(deleteFood, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export function useCreateFood() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function createFood(food: Omit<FoodWeek, "_id">) {
    if (isNil(food) || !tokenQuery.data?.token) {
      return
    }
    const body = {
      ...food,
      date: moment(food.date).toDate().toISOString(),
    }
    const result = await axios.post(`${API_URI}/${getSchool()}/food`, body, {
      headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
    })
    return result.data
  }

  return useMutation(createFood, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export function useUpdateFood() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function update(food: FoodWeek) {
    if (isNil(food) || !tokenQuery.data?.token) {
      return
    }
    const body = {
      ...food,
      date: moment(food.date).toDate().toISOString(),
      _id: undefined,
    }
    const result = await axios.patch(
      `${API_URI}/${getSchool()}/food/${food._id}`,
      body,
      {
        headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
      }
    )
    return result.data
  }

  return useMutation(update, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries(queryKey)
    },
  })
}
