import {
  Autocomplete,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import Kalend, { CalendarEvent, CalendarView } from "kalend"
import "kalend/dist/styles/index.css" // import styles
import moment from "moment"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { SCHOOL } from "../../constants"
import { getSchool } from "../../data/school-setup"
import { useGetTeachers } from "../../data/teacher"
import {
  TimetableBlock,
  TimetableType,
  useGetClasses,
  useGetTimetable,
} from "../../data/timetable"
import { spacing } from "../../ui/spacing"

export function Timetable() {
  const [selectedClass, setSelectedClass] = useState<string | null>(null)
  const [selectedTeacher, setSelectedTeacher] = useState<string | null>(null)
  const [selectedRoom, setSelectedRoom] = useState<string | null>(null)

  const [state, setState] = useState<{
    school: SCHOOL
    type: TimetableType
    identifier: string
    week: string
  }>({
    school: getSchool(),
    type: TimetableType.CLASS,
    identifier: "",
    week: "",
  })

  const weeks = Array.from(Array(30).keys()).map((i) => {
    return moment().add(i, "weeks").isoWeek().toString()
  })

  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [initialDate, setInitialDate] = useState<string>(
    new Date().toISOString()
  )

  const classes = useGetClasses()
  const teachers = useGetTeachers()
  const timetable = useGetTimetable(state)

  async function handleSearch() {
    timetable.refetch()
  }

  useEffect(() => {
    const events: CalendarEvent[] =
      timetable.data?.map((item) => {
        return {
          startAt: item.start,
          endAt: item.end,
          timezoneStartAt: "Europe/Stockholm",
          timezoneEndAt: "Europe/Stockholm",
          id: item.id,
          color: "white",
          summary: item.text,
        }
      }) ?? []
    setEvents(events)
    setInitialDate(events?.[0]?.startAt ?? new Date().toISOString())
  }, [timetable.data])

  return (
    <>
      <SelectorWrapper>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={classes?.data?.map((item: any) => item.name) ?? []}
          onChange={(event, value) => {
            setSelectedRoom(null)
            setSelectedTeacher(null)
            setSelectedClass(value)
            setState({
              ...state,
              type: TimetableType.CLASS,
              identifier: value ?? "",
            })
          }}
          value={selectedClass}
          renderInput={(params) => <TextField {...params} label="Klass" />}
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={
            teachers?.data?.map(
              (item: any) => `(${item.signature}) ${item.name}`
            ) ?? []
          }
          onChange={(event, value) => {
            setState({
              ...state,
              type: TimetableType.TEACHER,
              identifier: value?.split("(")[1].split(")")[0] ?? "",
            })
            setSelectedRoom("")
            setSelectedTeacher(value)
            setSelectedClass("")
          }}
          value={selectedTeacher}
          sx={{ width: "250px" }}
          renderInput={(params) => <TextField {...params} label="Lärare" />}
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={[]}
          onChange={(event, value) => {
            setState({
              ...state,
              type: TimetableType.ROOM,
              identifier: value ?? "",
            })
            setSelectedClass("")
            setSelectedTeacher("")
          }}
          value={selectedRoom}
          renderInput={(params) => <TextField {...params} label="Rum" />}
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={weeks}
          freeSolo
          autoSelect
          onChange={(event, value) => {
            setState({ ...state, week: value ?? "" })
          }}
          value={state.week}
          renderInput={(params) => <TextField {...params} label="Vecka" />}
        />

        <Button variant="contained" onClick={handleSearch}>
          {timetable.isLoading ? (
            <CircularProgress
              sx={{
                height: "24px !important",
                width: "24px !important",
                color: "white",
              }}
            />
          ) : (
            "Sök"
          )}
        </Button>
      </SelectorWrapper>

      <CalenderWrapper>
        <Kalend
          key={initialDate}
          events={events}
          initialDate={initialDate}
          hourHeight={60}
          initialView={CalendarView.WEEK}
          showWeekNumbers={true}
          disabledViews={[
            CalendarView.DAY,
            CalendarView.MONTH,
            CalendarView.THREE_DAYS,
            CalendarView.AGENDA,
          ]}
          timeFormat={"24"}
          weekDayStart={"Monday"}
          calendarIDsHidden={["work"]}
          language={"en"}
          disabledDragging={true}
          isDark={true}
          focusHour={8}
          autoScroll={true}
        />
      </CalenderWrapper>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Lektion</TableCell>
              <TableCell>CourseCode</TableCell>
              <TableCell>CourseName</TableCell>
              <TableCell>TeacherName</TableCell>
              <TableCell>TeacherSign</TableCell>
              <TableCell>Room</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timetable.data
              ?.sort((a, b) => (a.start > b.start ? 1 : -1))
              ?.map((row: TimetableBlock) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.text}</TableCell>
                  <TableCell>{row.courseCode}</TableCell>
                  <TableCell>{row.courseName}</TableCell>
                  <TableCell>{row.teacherName}</TableCell>
                  <TableCell>{row.teacherSignature}</TableCell>
                  <TableCell>{row.room}</TableCell>
                  <TableCell>{moment(row.start).format("l HH:mm")}</TableCell>
                  <TableCell>{moment(row.end).format("l HH:mm")}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const SelectorWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing.L};
  > div {
    min-width: 150px;
    margin-left: ${spacing.L};
  }
  > button {
    min-width: 100px;
    margin-left: ${spacing.L};
  }
`

const CalenderWrapper = styled.div`
  height: 1000px;
`
