import { Box, createTheme, ThemeProvider } from "@mui/material"
import { useState } from "react"
import { Sidebar } from "../components/sidebar"
import { SCHOOL } from "../constants"
import { getSchool } from "../data/school-setup"
import { spacing } from "./spacing"

let primary = "#7483c1"
switch (getSchool()) {
  case SCHOOL.BORGAR:
    primary = "#046a38"
    break
  case SCHOOL.MIG:
    primary = "#368d22"
    break
  case SCHOOL.PAULI:
    primary = "#7483c1"
    break
}

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#28272c",
      paper: "#2c2b30",
    },
  },
})

export const lightTheme = createTheme({
  typography: {
    h4: {
      color: "rgba(0,0,0,0.87)",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: primary,
    },
    background: {
      paper: "#f4f4f4",
    },
  },
})

export function AppLayout(props: any) {
  const darkMode = useState(
    window.localStorage.getItem("darkMode") === "true" ?? false
  )

  return (
    <>
      <ThemeProvider theme={darkMode[0] ? darkTheme : lightTheme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar darkMode={darkMode} />
          <Box
            sx={{
              flex: 1,
              flexDirection: "column",
              marginLeft: spacing.XL,
              marginTop: spacing.XL,
            }}
          >
            {props.children}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  )
}
