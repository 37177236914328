import axios from "axios"
import { isNil } from "lodash"
import moment from "moment"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { API_URI } from "../constants"
import { useGetToken } from "./auth"
import { getSchool } from "./school-setup"

export interface CanceledClass {
  _id: string
  startsAt: string
  endsAt: string
  school: string
  teacherName?: string
  information: string
}

export function useGetCanceled() {
  const tokenQuery = useGetToken()

  async function getCanceled() {
    const token = tokenQuery.data?.token
    if (token) {
      const result = await axios.get(`${API_URI}/${getSchool()}/canceled`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return result.data
    }
  }

  return useQuery(["canceled"], getCanceled)
}

export function useDeleteCanceled() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()
  

  async function deleteCanceled(id: string) {
    const result = await axios.delete(
      `${API_URI}/${getSchool()}/canceled/${id}`,
      {
        headers: { Authorization: `Bearer ${tokenQuery.data?.token}` },
      }
    )
    return result.data
  }

  return useMutation(deleteCanceled, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries("canceled")
    },
  })
}

export function useCreateCanceled() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function createCanceled(canceled: Omit<CanceledClass, "_id">) {
    if (isNil(canceled) || !tokenQuery.data?.token) {
      return
    }
    const result = await axios.post(
      `${API_URI}/${getSchool()}/canceled`,
      {
        startsAt: moment(canceled.startsAt).toDate().toISOString(),
        endsAt: moment(canceled.endsAt).toDate().toISOString(),
        school: canceled.school,
        information: canceled.information,
        teacherName: canceled.teacherName,
      },
      { headers: { Authorization: `Bearer ${tokenQuery.data?.token}` } }
    )
    return result.data
  }

  return useMutation(createCanceled, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries("canceled")
    },
  })
}

export function useUpdateCanceled() {
  const tokenQuery = useGetToken()
  const queryClient = useQueryClient()

  async function update(canceled: CanceledClass) {
    if (isNil(canceled) || !tokenQuery.data?.token) {
      return
    }
    const result = await axios.patch(
      `${API_URI}/${getSchool()}/canceled/${canceled._id}`,
      {
        startsAt: moment(canceled.startsAt).toDate().toISOString(),
        endsAt: moment(canceled.endsAt).toDate().toISOString(),
        school: canceled.school,
        information: canceled.information,
        teacherName: canceled.teacherName,
      },
      { headers: { Authorization: `Bearer ${tokenQuery.data?.token}` } }
    )
    return result.data
  }

  return useMutation(update, {
    onSuccess: (data) => {
      console.log(data)
      queryClient.invalidateQueries("canceled")
    },
  })
}
