import axios from "axios"
import { isNil } from "lodash"
import { useQuery } from "react-query"
import { API_URI, SCHOOL } from "../constants"
import { getSchool } from "./school-setup"

export enum TimetableType {
  CLASS = "class",
  TEACHER = "teacher",
  ROOM = "room",
  PNR = "pnr",
}

export interface TimetableBlock {
  courseCode?: string
  courseName?: string
  end: string
  id: string
  start: string
  teacherName?: string
  teacherSignature?: string
  text: string
  room?: string
}

const queryKey = "timetable"

export function useGetTimetable({
  school,
  type,
  identifier,
  week,
}: {
  school: SCHOOL
  type: TimetableType
  identifier: string
  week: string
}) {
  async function getTimetable(
    school: SCHOOL,
    type: TimetableType,
    identifier: string,
    week: string
  ) {
    if (identifier === "" || isNil(identifier)) {
      return
    }
    const result = await axios.get(
      `${API_URI}/${school}/timetable/?week=${week}&type=${type}&id=${identifier}`
    )
    return result.data.data
  }

  return useQuery<TimetableBlock[]>(
    [queryKey, school, type, identifier, week],
    () => getTimetable(school, type, identifier, week),
    { enabled: false, retry: false }
  )
}

export function useGetClasses() {
  async function getfunction() {
    const result = await axios.get(`${API_URI}/${getSchool()}/classes`)
    return result.data
  }

  return useQuery<string[]>(["classes"], getfunction)
}
