import { Button, Dialog, TextField } from "@mui/material/"
import { useState } from "react"
import styled from "styled-components"
import { getSchool } from "../../data/school-setup"
import { Teacher, useCreateTeacher, useUpdateTeacher } from "../../data/teacher"
import { spacing } from "../../ui/spacing"

export function TeacherEdit({
  open,
  setOpen,
  teacher,
}: {
  open: boolean
  setOpen: any
  teacher?: Teacher
}) {
  const createFunction = useCreateTeacher()
  const updateFunction = useUpdateTeacher()

  const [state, setState] = useState<Omit<Teacher, "_id">>({
    school: getSchool(),
    name: teacher?.name ?? "",
    signature: teacher?.signature ?? "",
    email: teacher?.email ?? "",
    office: teacher?.office ?? "",
    phone: teacher?.phone ?? "",
  })

  async function handleSave() {
    if (teacher) {
      await updateFunction.mutateAsync({ _id: teacher._id, ...state })
    } else {
      await createFunction.mutateAsync(state)
    }
    setOpen(false)
  }

  async function handleChange(e: any) {
    if (e.target.name.includes(".")) {
      const keys = e.target.name.split(".")
      setState({ ...state, [keys[0]]: { [keys[1]]: e.target.value } })
    } else {
      setState({ ...state, [e.target.name]: e.target.value })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogWrapper>
        <h1>{teacher ? "Ändra" : "Ny"} Lärare</h1>

        <TextField
          label="Namn"
          variant="outlined"
          name="name"
          value={state.name}
          onChange={handleChange}
        ></TextField>

        <TextField
          label="Signatur"
          variant="outlined"
          name="signature"
          value={state.signature}
          onChange={handleChange}
        ></TextField>

        <TextField
          label="Kontor"
          variant="outlined"
          name="office"
          value={state.office}
          onChange={handleChange}
        ></TextField>

        <TextField
          label="Telefon"
          variant="outlined"
          name="phone"
          value={state.phone}
          onChange={handleChange}
        ></TextField>

        <TextField
          label="Telefon"
          variant="outlined"
          name="phone"
          value={state.phone}
          onChange={handleChange}
        ></TextField>

        <Button variant="contained" onClick={handleSave}>
          Spara
        </Button>
      </DialogWrapper>
    </Dialog>
  )
}

const DialogWrapper = styled.div`
  padding-bottom: ${spacing.XL};
  padding-right: ${spacing.XL};
  padding-left: ${spacing.XL};
  padding-top: ${spacing.M};
  min-width: 500px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: ${spacing.XL};
  }
`
