import axios, { AxiosError } from "axios"
import { API_URI } from "../constants"
import { getSchool } from "./school-setup"

export async function warmUp(): Promise<void> {
  console.log("Warming up lambda...")
  try {
    const url = API_URI
    await axios.get(`${url}/${getSchool()}/canceled`)
  } catch (err) {
    if (err instanceof AxiosError) {
    }
  }
}
