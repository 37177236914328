import { Button, Dialog, TextField } from "@mui/material/"
import { DateTimePicker } from "@mui/x-date-pickers"
import axios from "axios"
import { isNil } from "lodash"
import moment from "moment"
import { useCallback, useRef, useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import styled from "styled-components"
import { API_URI } from "../../constants"
import { useGetToken } from "../../data/auth"
import {
  NewsArticle,
  useCreateArticle,
  useUpdateArticle,
} from "../../data/news"
import { getSchool } from "../../data/school-setup"
import { QuillEditor } from "../../ui/rich-editor"
import { spacing } from "../../ui/spacing"
import "./quill.css"

export function NewsEdit({
  open,
  setOpen,
  article,
}: {
  open: boolean
  setOpen: any
  article?: NewsArticle
}) {
  const createFunction = useCreateArticle()
  const updateFunction = useUpdateArticle()
  const defaultDate = moment().format("YYYY-MM-DDTHH:MM")
  const quillRef = useRef<QuillEditor>(null)
  const quillEngRef = useRef<QuillEditor>(null)

  const [title, setTitle] = useState(article?.title ?? "")
  const [titleEng, setEngTitle] = useState(article?.titleEng ?? "")
  const [date, setDate] = useState(article?.date ?? defaultDate)
  const [content, setContent] = useState(JSON.parse(article?.content ?? "{}"))
  const [contentEng, setContentEng] = useState(
    JSON.parse(article?.contentEng ?? "{}")
  )
  const [english, setEnglish] = useState(
    !isNil(article?.contentPlainEng) ?? false
  )

  const tokenQuery = useGetToken()

  async function handleSave() {
    const editor = (quillRef.current as any).getEditor()
    const contentJson = editor.getContents()
    let contentPlainEng, contentEng

    let contentPlain = ""
    for (let i = 0; i < contentJson["ops"].length; i++) {
      const tmp = contentJson["ops"][i]
      if (tmp["insert"]["image"]) {
        contentPlain += "[Bild]"
        continue
      }
      contentPlain += tmp["insert"]
    }

    if (!isNil(quillEngRef.current)) {
      const editor2 = (quillEngRef.current as any).getEditor()
      const contentJsonEng = editor2.getContents()
      contentPlainEng = ""
      for (let i = 0; i < contentJsonEng["ops"].length; i++) {
        const tmp = contentJsonEng["ops"][i]
        if (tmp["insert"]["image"]) {
          contentPlainEng += "[Bild]"
          continue
        }
        contentPlainEng += tmp["insert"]
      }
      contentEng = JSON.stringify(contentJsonEng)
    }

    const content = JSON.stringify(contentJson)

    if (!isNil(article)) {
      // prettier-ignore
      await updateFunction.mutateAsync({ title, date, content, contentEng, titleEng, contentPlainEng, contentPlain, "_id": article._id, school: article.school ?? getSchool() })
    } else {
      await createFunction.mutateAsync({
        title,
        titleEng,
        date,
        content,
        contentPlain,
        contentEng,
        contentPlainEng,
        school: getSchool(),
      })
    }
    setOpen(false)
  }

  const uploadImage = useCallback(() => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", ".jpeg, .jpg, .png")
    input.click()
    input.addEventListener("change", async () => {
      if (isNil(input.files)) {
        return
      }
      const file = input.files[0]
      const formData = new FormData()
      formData.append("image", file)
      try {
        const res = await axios.post(
          `${API_URI}/${getSchool()}/news/images`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${tokenQuery.data?.token}`,
            },
          }
        )
        const editor = quillRef.current?.getEditor()
        if (isNil(editor)) {
          return
        }
        const range = editor.getSelection()
        if (isNil(range)) {
          return
        }
        editor.format("align", "center")
        editor.insertEmbed(range.index, "image", res.data.link)
        editor.setSelection((range.index + 1) as any)
      } catch (err) {
        console.log(err)
      }
    })
  }, [tokenQuery, quillRef])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogWrapper>
        <h1>{article ? "Ändra" : "Ny"} post</h1>
        <TextField
          label="Titel"
          variant="outlined"
          multiline
          minRows={1}
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        {english && (
          <TextField
            label="Titel (Engelska)"
            variant="outlined"
            multiline
            minRows={1}
            name="titleEng"
            value={titleEng}
            onChange={(e) => setEngTitle(e.target.value)}
          />
        )}

        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={content}
          placeholder="Innehåll"
          onChange={(value, delta, source, editor) => {
            if (
              JSON.stringify(content) !== JSON.stringify(editor.getContents())
            ) {
              setContent(editor.getContents())
            }
          }}
          formats={["link", "italic", "align", "image", "list"]}
          modules={{
            toolbar: {
              container: [
                ["italic"],
                ["link", "image"],
                [
                  {
                    align: "",
                  },
                  {
                    align: "center",
                  },
                  {
                    align: "right",
                  },
                  {
                    align: "justify",
                  },
                ],
                [
                  {
                    list: "ordered",
                  },
                  {
                    list: "bullet",
                  },
                ],
              ],
              handlers: {
                image: uploadImage,
              },
            },
          }}
        />

        {english && (
          <ReactQuill
            ref={quillEngRef}
            theme="snow"
            value={contentEng}
            placeholder="Innehåll (engelska)"
            onChange={(value, delta, source, editor) => {
              if (
                JSON.stringify(contentEng) !==
                JSON.stringify(editor.getContents())
              ) {
                setContentEng(editor.getContents())
              }
            }}
            formats={["link", "italic", "align", "image", "list"]}
            modules={{
              toolbar: {
                container: [
                  ["italic"],
                  ["link", "image"],
                  [
                    {
                      align: "",
                    },
                    {
                      align: "center",
                    },
                    {
                      align: "right",
                    },
                    {
                      align: "justify",
                    },
                  ],
                  [
                    {
                      list: "ordered",
                    },
                    {
                      list: "bullet",
                    },
                  ],
                ],
                handlers: {
                  image: uploadImage,
                },
              },
            }}
          />
        )}

        <DateTimePicker
          label="Datum"
          ampm={false}
          disablePast={article ? false : true}
          hideTabs={false}
          showToolbar={true}
          shouldDisableYear={() => {
            return true
          }}
          renderInput={(params) => <TextField {...params} />}
          value={date}
          onChange={(value) => {
            setDate(value ?? "")
          }}
        />

        <Button
          sx={{ marginBottom: spacing.M }}
          variant="contained"
          onClick={() => setEnglish(!english)}
        >
          {english ? "Ta bort engelska" : "Lägg till engelska"}
        </Button>

        <Button variant="contained" onClick={handleSave}>
          Spara
        </Button>
      </DialogWrapper>
    </Dialog>
  )
}

const DialogWrapper = styled.div`
  padding-bottom: ${spacing.XL};
  padding-right: ${spacing.XL};
  padding-left: ${spacing.XL};
  padding-top: ${spacing.M};
  min-width: 500px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: ${spacing.XL};
  }
`
