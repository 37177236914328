import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Typography from "@mui/material/Typography"
import moment from "moment"
import { useEffect, useState } from "react"
import { useIsAuthenticated } from "../../data/auth"
import {
  CanceledClass,
  useDeleteCanceled,
  useGetCanceled,
} from "../../data/canceled"
import { DeleteDialog } from "../../ui/delete-dialog"
import { CanceledEdit } from "./canceled-edit"

export function CanceledOverview() {
  const getCanceled = useGetCanceled()
  const deleteCanceled = useDeleteCanceled()
  const isAuthenticated = useIsAuthenticated()

  const [classToEdit, setClassToEdit] = useState(undefined)
  const [edit, setEdit] = useState(false)
  const [classToDelete, setClassToDelete] = useState(undefined)

  async function handleDelete(clz: CanceledClass) {
    await deleteCanceled.mutateAsync(clz._id)
    setClassToDelete(undefined)
  }

  useEffect(() => {
    if (!getCanceled.data) {
      getCanceled.refetch()
    }
  }, [getCanceled, isAuthenticated.data])

  return (
    <>
      <Typography variant="h3" component="div" gutterBottom>
        Inställda lektioner
      </Typography>
      <Button disabled={getCanceled.isLoading} onClick={() => setEdit(true)}>
        Ny schema-ändring
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Info</TableCell>
              <TableCell>Från</TableCell>
              <TableCell>Till</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCanceled.isLoading ? (
              <CircularProgress />
            ) : (
              getCanceled.data
                ?.sort(
                  (a: CanceledClass, b: CanceledClass) =>
                    moment(a.startsAt) < moment(b.startsAt)
                )
                ?.map((clz: any) => (
                  <TableRow key={clz._id}>
                    <TableCell>
                      {clz.teacherName ? clz.teacherName + ": " : ""}
                      {clz.information}
                    </TableCell>
                    <TableCell>
                      {moment(clz.startsAt).format("HH:mm DD MMM")}
                    </TableCell>
                    <TableCell>
                      {moment(clz.endsAt).format("HH:mm DD MMM")}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setClassToEdit(clz)
                          setEdit(true)
                        }}
                      >
                        Ändra
                      </Button>
                      <Button onClick={() => setClassToDelete(clz)}>
                        Ta bort
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {edit && (
        <CanceledEdit open={edit} setOpen={setEdit} classToEdit={classToEdit} />
      )}
      {classToDelete && (
        <DeleteDialog
          onClose={() => setClassToDelete(undefined)}
          onConfirm={() => handleDelete(classToDelete)}
          text="Är du säker på att du vill ta bort denna schema-ändring?"
          title="Ta bort schema-ändring"
        />
      )}
    </>
  )
}
