import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"

export function DeleteDialog({
  onClose,
  onConfirm,
  title,
  text,
}: {
  onClose: any
  onConfirm: any
  title: string
  text: string
}) {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Avbryt
        </Button>
        <Button onClick={onConfirm}>Ta bort</Button>
      </DialogActions>
    </Dialog>
  )
}
