import axios from "axios"
import { useQuery } from "react-query"
import { API_URI } from "../constants"
import { useGetToken } from "./auth"

const queryKey = "files"

export function useGetFiles() {
  const tokenQuery = useGetToken()

  async function get() {
    const token = tokenQuery.data?.token
    if (token) {
      const result = await axios.get(`${API_URI}/r2`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return result.data
    }
  }

  return useQuery([queryKey], get)
}
