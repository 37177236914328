import { Route, Routes } from "react-router-dom"
import { CanceledOverview } from "./canceled/canceled-overview"
import { FoodOverview } from "./food/food-overview"
import { Login } from "./login"
import { NewsOverview } from "./news/news-overview"
import { PrivateRoute } from "./private-route"
import { ProfileView } from "./profile"
import { R2View } from "./r2-viewer/r2-viewer"
import { TeacherOverview } from "./teachers/teacher-overview"
import { Timetable } from "./timetable/timetable-viewer"

export function Router() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/canceled"
          element={<PrivateRoute component={<CanceledOverview />} />}
        />
        <Route
          path="/news"
          element={<PrivateRoute component={<NewsOverview />} />}
        />
        <Route
          path="/timetable"
          element={<PrivateRoute component={<Timetable />} />}
        />
        <Route
          path="/food"
          element={<PrivateRoute component={<FoodOverview />} />}
        />
        <Route
          path="/teachers"
          element={<PrivateRoute component={<TeacherOverview />} />}
        />
        <Route path="/r2" element={<PrivateRoute component={<R2View />} />} />
        <Route
          path="/profile"
          element={<PrivateRoute component={ProfileView} />}
        />
      </Routes>
    </>
  )
}
