import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Typography from "@mui/material/Typography"
import { useGetFiles } from "../../data/r2"

interface FileType {
  key: string
  filename: string
  size: string
  type: string
}

export function R2View() {
  const getFiles = useGetFiles()

  return (
    <>
      <Typography variant="h3" component="div" gutterBottom>
        R2
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Filename</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Size</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getFiles.data?.map((file: FileType) => (
              <TableRow key={file.key}>
                <TableCell>{file.filename}</TableCell>
                <TableCell></TableCell>
                <TableCell>{file.type}</TableCell>
                <TableCell>{file.size}</TableCell>
                <TableCell>
                  <Button>View</Button>
                  <Button>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
