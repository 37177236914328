import { Button, CircularProgress, Dialog, TextField } from "@mui/material/"
import Autocomplete from "@mui/material/Autocomplete"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { DateTimePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { useMemo, useState } from "react"
import styled from "styled-components"
import {
  CanceledClass,
  useCreateCanceled,
  useUpdateCanceled,
} from "../../data/canceled"
import { getSchool } from "../../data/school-setup"
import { useGetTeachers } from "../../data/teacher"
import { spacing } from "../../ui/spacing"

export function CanceledEdit({
  open,
  setOpen,
  classToEdit,
}: {
  open: boolean
  setOpen: any
  classToEdit?: CanceledClass
}) {
  const createFunction = useCreateCanceled()
  const updateFunction = useUpdateCanceled()
  const teachers = useGetTeachers()
  const defaultDate = moment().format("YYYY-MM-DDTHH:MM")
  const [state, setState] = useState<Omit<CanceledClass, "_id">>({
    information: classToEdit?.information ?? "",
    startsAt: classToEdit?.startsAt ?? defaultDate,
    endsAt: classToEdit?.endsAt ?? defaultDate,
    school: classToEdit?.school ?? getSchool(),
  })
  const [type, setType] = useState(
    classToEdit?.teacherName ? "lärare" : "lektion"
  )

  const teacherNames: string[] = useMemo<string[]>(() => {
    return (
      teachers.data?.map((item: any) => `(${item.signature}) ${item.name}`) ??
      []
    )
  }, [teachers.data])

  async function handleSave() {
    if (classToEdit) {
      await updateFunction.mutateAsync({ ...state, _id: classToEdit._id })
    } else {
      await createFunction.mutateAsync(state)
    }
    setOpen(false)
  }

  async function handleChange(e: any) {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogWrapper>
        <h1>{classToEdit ? "Uppdatera" : "Lägg till"} schema-ändring</h1>
        <ToggleButtonGroup
          value={type}
          exclusive
          color="primary"
          onChange={(value, newValue) => {
            setType(newValue)
          }}
          aria-label="text alignment"
        >
          <ToggleButton value="lärare" aria-label="left aligned">
            Lärare
          </ToggleButton>
          <ToggleButton value="lektion" aria-label="centered">
            Lektion
          </ToggleButton>
        </ToggleButtonGroup>

        {type === "lärare" && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={teacherNames ?? []}
            onChange={(event, value) => {
              handleChange({ target: { name: "teacherName", value } })
            }}
            renderInput={(params) => <TextField {...params} label="Lärare" />}
          />
        )}

        <TextField
          label="Information"
          variant="outlined"
          multiline
          minRows={2}
          name="information"
          value={state.information}
          onChange={handleChange}
        ></TextField>

        <DateTimePicker
          label="Från"
          ampm={false}
          hideTabs={false}
          showToolbar={true}
          shouldDisableYear={() => {
            return true
          }}
          renderInput={(params) => <TextField {...params} />}
          value={state.startsAt}
          onChange={(value) => {
            handleChange({ target: { name: "startsAt", value } })
          }}
        />

        <DateTimePicker
          label="Till"
          ampm={false}
          hideTabs={false}
          minDate={moment(state.startsAt)}
          renderInput={(params) => <TextField {...params} />}
          value={state.endsAt}
          onChange={(value) => {
            handleChange({ target: { name: "endsAt", value } })
          }}
        />

        <Button variant="contained" onClick={handleSave}>
          {updateFunction.isLoading || createFunction.isLoading ? (
            <CircularProgress />
          ) : (
            "Spara"
          )}
        </Button>
      </DialogWrapper>
    </Dialog>
  )
}

const DialogWrapper = styled.div`
  padding-bottom: ${spacing.XL};
  padding-right: ${spacing.XL};
  padding-left: ${spacing.XL};
  padding-top: ${spacing.M};
  min-width: 500px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: ${spacing.XL};
  }
`
