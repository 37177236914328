import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Typography from "@mui/material/Typography"
import moment from "moment"
import { useEffect, useState } from "react"
import { useIsAuthenticated } from "../../data/auth"

import { FoodWeek, useDeleteFood, useGetFood } from "../../data/food"
import { DeleteDialog } from "../../ui/delete-dialog"
import { FoodEdit } from "./food-edit"

export function FoodOverview() {
  const getFood = useGetFood()
  const deleteFood = useDeleteFood()
  const isAuthenticated = useIsAuthenticated()

  const [edit, setEdit] = useState(false)
  const [foodToEdit, setFoodToEdit] = useState<FoodWeek | undefined>(undefined)
  const [foodToDelete, setFoodToDelete] = useState<FoodWeek | undefined>(
    undefined
  )

  async function handleDelete(food: FoodWeek) {
    await deleteFood.mutateAsync(food._id)
    setFoodToDelete(undefined)
  }

  useEffect(() => {
    if (!getFood.data) {
      getFood.refetch()
    }
  }, [getFood, isAuthenticated.data])

  useEffect(() => {
    if (edit === false) {
      setFoodToEdit(undefined)
    }
  }, [edit])

  return (
    <>
      <Typography variant="h3" component="div" gutterBottom>
        Matsedel
      </Typography>
      <Button disabled={getFood.isLoading} onClick={() => setEdit(true)}>
        Lägg till vecka
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Vecka</TableCell>
              <TableCell>Måndag</TableCell>
              <TableCell>Tisdag</TableCell>
              <TableCell>Onsdag</TableCell>
              <TableCell>Torsday</TableCell>
              <TableCell>Fredag</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getFood.isLoading ? (
              <CircularProgress />
            ) : (
              getFood.data
                ?.sort((a: FoodWeek, b: FoodWeek) => a.date < b.date)
                ?.map((food: FoodWeek) => (
                  <TableRow key={food._id}>
                    <TableCell>{moment(food.date).isoWeek()}</TableCell>
                    <TableCell>{food.monday.sv}</TableCell>
                    <TableCell>{food.tuesday.sv}</TableCell>
                    <TableCell>{food.wednesday.sv}</TableCell>
                    <TableCell>{food.thursday.sv}</TableCell>
                    <TableCell>{food.friday.sv}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setFoodToEdit(food)
                          setEdit(true)
                        }}
                      >
                        Ändra
                      </Button>
                      <Button onClick={() => setFoodToDelete(food)}>
                        Ta bort
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {edit && <FoodEdit open={edit} setOpen={setEdit} foodWeek={foodToEdit} />}
      {foodToDelete && (
        <DeleteDialog
          onConfirm={() => handleDelete(foodToDelete)}
          onClose={() => setFoodToDelete(undefined)}
          title="Ta bort matsedel"
          text="Är du säker på att du vill ta bort matsedeln?"
        />
      )}
    </>
  )
}
