import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useIsAuthenticated } from "../../data/auth"

import { Teacher, useDeleteTeacher, useGetTeachers } from "../../data/teacher"
import { DeleteDialog } from "../../ui/delete-dialog"
import { TeacherEdit } from "./teacher-edit"

export function TeacherOverview() {
  const getTeachers = useGetTeachers()
  const deleteFunction = useDeleteTeacher()

  const isAuthenticated = useIsAuthenticated()

  const [edit, setEdit] = useState(false)
  const [teacherToEdit, setTeacherToEdit] = useState<Teacher | undefined>(
    undefined
  )
  const [teacherToDelete, setTeacherToDelete] = useState<Teacher | undefined>(
    undefined
  )

  async function handleDelete(teacher: Teacher) {
    await deleteFunction.mutateAsync(teacher._id)
    setTeacherToDelete(undefined)
  }

  useEffect(() => {
    if (!getTeachers.data) {
      getTeachers.refetch()
    }
  }, [getTeachers, isAuthenticated.data])

  return (
    <>
      <Typography variant="h3" component="div" gutterBottom>
        Lärare
      </Typography>
      <Button
        onClick={() => {
          setTeacherToEdit(undefined)
          setEdit(true)
        }}
      >
        Lägg till
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell></TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Kontor</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getTeachers.data
              ?.sort((a: Teacher, b: Teacher) => a.name > b.name)
              .map((teacher: Teacher) => (
                <TableRow key={teacher._id}>
                  <TableCell>{teacher.name}</TableCell>
                  <TableCell>{teacher.signature}</TableCell>
                  <TableCell>{teacher.email}</TableCell>
                  <TableCell>{teacher.office}</TableCell>
                  <TableCell>{teacher.phone}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setTeacherToEdit(teacher)
                        setEdit(true)
                      }}
                    >
                      Ändra
                    </Button>
                    <Button onClick={() => setTeacherToDelete(teacher)}>
                      Ta bort
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {edit && (
        <TeacherEdit open={edit} setOpen={setEdit} teacher={teacherToEdit} />
      )}
      {teacherToDelete && (
        <DeleteDialog
          onClose={() => setTeacherToDelete(undefined)}
          onConfirm={() => handleDelete(teacherToDelete)}
          text="Är du säker på att du vill ta bort läraren?"
          title="Ta bort lärare"
        />
      )}
    </>
  )
}
